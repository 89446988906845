























































import { Component, Prop, Mixins } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import {
  CourseLargeResource,
  CourseShortResource,
  GroupLargeResource,
  SetRecommendationsRequestPart,
  RecommendationPartResource,
} from '@/store/types'
// components
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import ButtonIconAction from '@/components/_uikit/buttons/ButtonIconAction.vue'
import SearchCourseModal from '@/components/modals/courses/SearchCourseModal.vue'

@Component({
  components: {
    Draggable,
    SwitchInput,
    ButtonTextIcon,
    ButtonIconAction,
    SearchCourseModal,
  },
})
export default class CoursesItemRecommendations extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private group!: GroupLargeResource

  private get recommendations() {
    return ManagerCoursesModule.program
  }

  private get courseID() {
    return +this.$route.params.courseID
  }

  private recommendedByDefault = false
  private showSearchCourseModal = false

  private recommendedCourses: RecommendationPartResource[] = []
  private selectedRecommendationCourses: CourseShortResource[] = []

  private isLoaded = false

  private showActions = false
  private actions = [
    {
      label: 'Удалить все',
      icon: '$trash',
      iconColor: 'red',
      callback: this.deleteAllRecommendedCourses,
    },
  ]

  private mounted () {
    this.fetchProgram()
    this.fetchRecommendedCourses()
  }

  private fetchProgram () {
    ManagerCoursesModule.fetchProgram(this.courseID)
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.control.courses' })
      })
      .finally(() => this.isLoaded = true)
  }

  private fetchRecommendedCourses() {
    ManagerCoursesModule.fetchRecommendedCourses(this.courseID)
      .then(response => {
        this.recommendedCourses = response.recommendations
        this.setSelectRecommendationCourses(response.recommendations)
        this.recommendedByDefault = response.recommendedByDefault
      })
      .catch(this.notifyError)
      .finally(() => this.isLoaded = true)
  }

  private setSelectRecommendationCourses(data: RecommendationPartResource[]) {
    this.selectedRecommendationCourses = data.map((course, index) => ({
      ...course,
      position: index + 1,
      closedForSales: false,
      annotation: '',
      educationStartAt: '',
      educationEndAt: '',
      salesEndAt: '',
      salesEndPassed: false,
      hiddenAt: '',
      createdAt: '',
      type: { name: '', value: '' },
      groupTypes: [],
      materials: 0,
      subject: { name: '', value: '' },
    }))
  }

  private handleCoursesSelect(value: CourseShortResource[]) {
    this.selectedRecommendationCourses = [...value]
    this.recommendedCourses = value.map((item, index) => {
      return {
        id: item.id,
        title: item.title,
        position: index,
      }
    })
  }

  private get normalizedRecommendedCourses() {
    return this.recommendedCourses.map((course: RecommendationPartResource, index: number) => ({
      id: course.id,
      position: index + 1,
    })) as SetRecommendationsRequestPart[]
  }

  private saveRecommendedCourses() {
    const payload = {
      courseID: this.courseID,
      body: {
        recommendedByDefault: this.recommendedByDefault,
        recommendations: this.normalizedRecommendedCourses,
      },
      options: null,
    }

    ManagerCoursesModule.setRecommendedCourses(payload)
      .then(response => {
        this.recommendedCourses = response.recommendations
        this.setSelectRecommendationCourses(response.recommendations)
        this.notifySuccess('Рекомендации успешно добавлены')
      })
      .catch(this.notifyError)
  }

  private deleteAllRecommendedCourses () {
    this.recommendedCourses = []
    this.selectedRecommendationCourses = []
  }

  private deleteRecommendedCourse (courseIndex: number) {
    this.recommendedCourses = this.recommendedCourses.filter((item, index) => index !== courseIndex)
    this.selectedRecommendationCourses = this.selectedRecommendationCourses.filter((item, index) => index !== courseIndex)
  }

  private handleCourseDrag() {
    this.recommendedCourses.forEach((course: RecommendationPartResource, index: number) => {
      course.position = index + 1
    })
  }
}
